import { AnimateSharedLayout } from 'framer-motion';
import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import AgendaItem from './AgendaItem';
import styles from './HeaderBanner.module.css';

const HeaderBanner = ({eventId}) => {

    const auth  = useContext(AuthContext);
    const { REACT_APP_API_URL } = process.env;
    const { sendRequest } = useHttpClient();
    const [liveItems, setLiveItems] = useState([]);
    const [agenda, setAgenda] = useState([]);
    const [update, setUpdate] = useState(false);
    const history = useHistory();
  

    useEffect(() => {

        if (auth.isLoggedIn) {
            const fetchAgenda = async () => {
              try {
                const response = await sendRequest(
                  `${REACT_APP_API_URL}/api/agenda/all/${eventId}`,
                  'POST',
                  {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                  }
                );
                console.log(response);
                if (response.agenda) {
                 setAgenda(response.agenda);
                 
                }
                
              } catch (err) {
                console.log(err);
              }
             }
             fetchAgenda()
          } else {
            console.log("Not authorised");
            history.push('/auth');
          }

    }, [eventId])

    useEffect(() => {
      var timerID = setInterval( () => {
        console.log('tick');
        setUpdate(current => current ? false : true)
      }, 5000 );
      return () => {
        clearInterval(timerID);
      }
    }, [])

    useEffect(() => {
    agenda && getCurrentTitles(agenda);
    }, [agenda])

    useEffect(() => {
      updateTitles(agenda);
    }, [update])



    const updateTitles = agendaData => {

      if (agendaData.length >= 3 ) {
      
        //console.log(getUtcTime());
        const utcTime = getUtcTime()
        let liveData = liveItems;

        const fullList = agendaData.sort(function(a, b) {
          var startTime = a.startTime
          var endTime = b.startTime
          if (startTime < endTime) {
            return -1;
          }
          if (startTime > endTime) {
            return 1;
          }
          return 0;
        });

       

        liveData.sort(function(a, b) {
          var startTime = a.startTime
          var endTime = b.startTime
          if (startTime < endTime) {
            return -1;
          }
          if (startTime > endTime) {
            return 1;
          }
          return 0;
        });

        if (liveData) {
         
          if (liveData.length > 0 ) {
            console.log(liveData[0].endTime.slice(0,5));
            console.log(utcTime);
            if (utcTime >= liveData[0].endTime.slice(0,5) ) {
              //console.log('utc');
             // console.log(utcTime);
              //console.log('end');
              //console.log(liveData[0].endTime);

              const idx = fullList.findIndex(item => item.id === liveData[1].id)
              const nextTitle = fullList[idx + 1];

              console.log(nextTitle);
              if (nextTitle) {
                liveData.shift()
                liveData = [...liveData, nextTitle]
                setLiveItems(c => [...liveData]);
              } else {
                liveData.shift()
                setLiveItems(c => [...liveData]);
              }
            }
          }
        }
      }
    }

    const getCurrentTitles = agendaData => {
    
      const utcTime = getUtcTime()

      if (agendaData) {
        if(agendaData.length >= 2) {
          const lastIdx = agendaData.length - 1
          const showStartTime = agendaData[0].startTime;
          const showEndTime = agendaData[lastIdx].endTime;

          if (utcTime < showStartTime) {
            // pre show 
            setLiveItems([agendaData[0], agendaData[1]]);
          } else if (utcTime > showEndTime) {
            // show finished
            setLiveItems(()=>[agendaData[lastIdx]]);
          } else {
            //during Show
            console.log('show running');
            const idx = agendaData.findIndex(item => {
              return item.startTime <= utcTime  &&  utcTime < item.endTime
            })

            console.log(idx);
        
            if (idx >= 0) {
              if (idx + 1 === agendaData.length) {
                setLiveItems([agendaData[idx]]);

              } else {
              if (1 < agendaData.length ) {
                setLiveItems([agendaData[idx], agendaData[idx +1]]);
              } else {
                // last item
                setLiveItems([agendaData[idx]])
              }
            }
            } 
          }
        } else if (agendaData.length ===  1) {
          setLiveItems([agendaData]);
        }
      } else {
        console.log('agenda data is less than 2');
      }
    }

    const getUtcTime = () => {
      const utcDate = moment.utc().format();
      const utcTime = utcDate.split('T')[1].substring(0, 5);
      return utcTime;
    }


    return (
        <section id={styles.headerBanner} >
             <div className={styles.labelsWrapper}>
                <span className={styles.label}>NOW</span>
                {liveItems && liveItems.length === 2 && <span className={styles.label}>Up Next</span>}
                </div>
            <div className={styles.headerWrapper}>
            <AnimateSharedLayout >
            { liveItems.length > 1 && liveItems.map((item, idx )=> (
                <AgendaItem key={item.id} title={item.title} subTitle={item.subTitle} startTime={item.startTime} endTime={item.endTime}  right={liveItems.length === (idx +1) &&  liveItems.length !== 1 ? true : false } />
            ))}
            </AnimateSharedLayout>
            </div>
        </section>
    )
}

export default HeaderBanner;