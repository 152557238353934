import React, { useState, useEffect, useContext, Fragment } from 'react';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { SocketContext } from '../../shared/context/socket';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useHttpClientNoHead } from '../../shared/hooks/http-hook-no-head';

import Branding from '../../shared/components/UIElements/Branding';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import LiveStream from '../../shared/components/Live/LiveStream';
import QandA from '../../shared/components/QandA/QandA';
import HeaderBanner from '../../shared/components/HeaderBanner/HeaderBanner';
import NewsMarquee from '../../shared/components/NewsMarquee/NewsMarquee';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

import styles from './Event.module.css';

const Event = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      duration: 5,
      x: -200,
    },
    in: {
      opacity: 1,
      duration: 2,
      x: 0,
    },
    out: {
      opacity: 0,
      duration: 5,
      x: -200,
    },
  };

  const history = useHistory();

  const { REACT_APP_API_URL } = process.env;
  const socket = useContext(SocketContext);
  const { sendARequest } = useHttpClientNoHead();
  const { email, userId, isLoggedIn, token, logout } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [event, setEvent] = useState();
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');

  // listen for new questions on WS
  useEffect(() => {
    socket.on('AUTH', dataFromServer => {
      wsAuthHandler(dataFromServer);
    });
    return () => {
      socket.off('AUTH');
    };
  }, [socket]);

  useEffect(() => {
    if (isLoggedIn) {
      const fetchEvent = async () => {
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/event/events`,
            'POST',
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            }
          );
          console.log(response);
          if (response.events) {
            if (response.events.length > 0) {
              console.log(response.events[0]);
              setEvent(response.events[0]);
              setTitle(response.events[0].title);
              setSubTitle(response.events[0].subTitle);
            }
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchEvent();
    } else {
      console.log('Not authorised');
      history.push('/auth');
    }
  }, []);

  // check connection status
  useEffect(() => {
    const checkConnStatus = async () => {
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/conn/status`,
          'POST',
          JSON.stringify({
            userId: userId,
            socketId: socket.id,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          }
        );
        console.log(response);
        if (!response.status) {
          setConnection(response.ticketNo);
        }
      } catch (err) {
        console.log(err);
      }
    };
    socket.id && userId && checkConnStatus();
  }, [socket.id]);

  const setConnection = async ticketNo => {
    // updates socket conn list if return with valid token
    try {
      const response = await sendARequest(`https://ip.nf/me.json`, 'GET');

      socket.emit('login', {
        userId: userId,
        email: email,
        ticketNo: ticketNo,
        ip: response.ip.ip,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const wsAuthHandler = data => {
    if (data) {
      if (data.userId) {
        switch (data.action) {
          case 'Ticket Invalid':
            invalidTicketHandler(data.userId);
            break;

          case 'Account Suspended':
            accountsuspendHandler(data.userId);
            break;

          default:
            break;
        }
      }
    }
  };

  const invalidTicketHandler = id => {
    if (id === userId) {
      socket.emit('logout', { socketId: socket.id });
      logout();
    }
  };

  const accountsuspendHandler = id => {
    if (id === userId) {
      socket.emit('logout', { socketId: socket.id });
      logout();
    }
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <motion.div
        className={styles.pageWrapper}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <Branding
          src="https://eventstreams.co/img/logo.svg"
          alt="client name"
          eventTitle={title}
          subTitle={subTitle}
          width="100px"
          height="65px"
        />
        {!isLoading && event && <HeaderBanner eventId={event.id} />}
        {!isLoading && event && <LiveStream eventId={event.id} />}
        {!isLoading && event && <NewsMarquee eventId={event.id} />}
        {isLoading && (
          <div className={styles.centerSpinner}>
            <LoadingSpinner />
          </div>
        )}
      </motion.div>
      <QandA />
    </Fragment>
  );
};

export default Event;
