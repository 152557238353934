import React, { useContext }  from 'react';
import { useHistory } from "react-router-dom";
import { motion, AnimatePresence } from 'framer-motion';
import Bowser from "bowser"; 
import { useTranslation } from 'react-i18next';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClientNoHead } from '../../shared/hooks/http-hook-no-head';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { SocketContext } from '../../shared/context/socket';

import styles from './AuthTicket.module.css';



const AuthTicket = props => {

  const { REACT_APP_API_URL } = process.env;

  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext);
  const socket = useContext(SocketContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { sendARequest } = useHttpClientNoHead();
  const agentStats = Bowser.parse(window.navigator.userAgent)

  const pageVariants = {
    initial: {
      opacity: 0,
     
    },
    in: {
      opacity: 1,
      duration:3,
     
    },
    out: {
      opacity: 0,
      duration:3,
      
    },
  }


  const submitHandler = async event => {
    event.preventDefault();

    const browserName = agentStats.browser.name
    const browserVerion = agentStats.browser.version
    const osName = agentStats.os.name
    const osVersion = agentStats.os.version
    const osVersionName = agentStats.os.versionName
    const platform = agentStats.platform.type
    
    let city = 'unknown';
    let country = 'unknown';
    let countryCode = 'unknown';
    let ip = 'unknown';
   
    try {
      const response = await sendARequest(`https://ip.nf/me.json`, 'GET');
      city = response.ip.city
      country = response.ip.country
      countryCode = response.ip.country_code
      ip = response.ip.ip
   
     
    } catch (err) {
      console.log(err);
    }

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/auth/ticket`,
        'POST',
        JSON.stringify({
          email: event.target.email.value,
          ticketNo: event.target.ticket.value.toUpperCase(),
          browserName: browserName,
          browserVerion: browserVerion,
          osName: osName,
          osVersion: osVersion,
          osVersionName: osVersionName,
          platform: platform,
          city: city,
          country: country,
          countryCode: countryCode,
          ip:ip
        }),
        {
          'Content-Type': 'application/json',
        }
        );
        
          socket.emit('login', { 
            userId:response.userId, 
            email:response.email, 
            ticketNo:event.target.ticket.value.toUpperCase(),
            ip: ip,
          });
        
       
        console.log(response);
        auth.login(response.userId, response.email, response.token);
        history.push("/");
    }
    catch (err) {
      console.log(err);
    }
  }

  const ticketOnChangeHandler = e => {
    console.log(e.target.value);
  }

  const navLoginHandler = () => {
    history.push("/register");
  };

    return (
          <AnimatePresence exitBeforeEnter>
            <ErrorModal error={error} onClear={clearError}/>
          
            <motion.div 
              key={'authTicket'}
              className="wrapper center col"
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
            > 
            
            <div className={styles.authWrapper}>
            <img className={styles.logo} src={`/logo.svg`} alt="logo" />
            
            <h1 className={styles.title}>{t("enter")}</h1>
            {isLoading && <LoadingSpinner />}

           {!isLoading && 
           <form onSubmit={submitHandler}>
                <Input id="email" name="email" type="email" placeholder={t(`email`)} inputClassName="no-group form-padding" />
                <Input id="ticket" name="ticket" type="text" placeholder={'ABC-123456-DEF'}  onChange={ticketOnChangeHandler} inputClassName={`no-group form-padding ${styles.ticketInput}`}/>
                <div className={styles.actions}>
                  <Button  type="submit" className={`button-primary ${styles.enterBtn}`}  title={t(`login`)}/>
                </div>
            </form>}
            {!isLoading && <div className={styles.actions}>
            <Card className={`secondary-card ${styles.regBoxWrapper} `}>
              <div className={styles.altContainer}>
                <p className={styles.altContainerText}>{t(`Not got a Ticket?`)}</p>
                <Button
                  type="button"
                  onClick={navLoginHandler}
                  className={`button-warning ${styles.registerBtn}`}
                  title={t(`register`)}
                />
              </div>
            </Card>
          </div>}
           
            </div>
            </motion.div>
         
          </AnimatePresence>
        );
};

export default AuthTicket;