import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Bowser from 'bowser';
import { useTranslation } from 'react-i18next';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';

import styles from './Register.module.css';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    duration: 3,
  },
  out: {
    opacity: 0,
    duration: 3,
  },
};

const Register = props => {
  const { REACT_APP_API_URL } = process.env;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [errors, setErrors] = useState();
  const [registeredData, setRregisteredData] = useState();
  const [submitted, setSubmitted] = useState(false);


  const navLoginHandler = () => {
    history.push("/");
  };

  const onSubmit = async data => {
    data.preventDefault();

    const email = data.target.email.value || null;
    const name = data.target.name.value || 'Unknown';
    const organisation = data.target.organisation.value || 'Not Given';

    if (email === null) {
      setErrors({ email: { type: 'required' } });
    } else {
      console.log(email);
      console.log(name);
      console.log(organisation);

      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/user/register-user`,
          'POST',
            JSON.stringify({
              email: email,
              name: name,
              organization: organisation
            }),
            {
              'Content-Type': 'application/json'
            }
          );
          console.log(response);
            setRregisteredData(response)
            setSubmitted(true);
          // if sucess load a sucess page tell them to await approval
      }
      catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Fragment>
    <AnimatePresence>
      <ErrorModal
        error={error}
        className={'fm-modal-sm'}
        onClear={clearError}
      />
      
      <motion.div 
              key='Regster'
              className="wrapper center col"
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
            > 
        <div id={styles.mainWrapper} className="auth-wrapper">
          <img className={styles.logo} src={`/logo.svg`} alt="logo" />
          <div className="signup-card" style={{ width: '100%' }}>
            <h1 className={styles.title}>{t('event registration')}</h1>
            {isLoading && <LoadingSpinner />}
            {!submitted && !isLoading && 
              <form onSubmit={onSubmit} className="signup-form">
                <Input
                  id="name"
                  name="name"
                  type="name"
                  placeholder="Name"
                  inputClassName="no-group form-padding"
                />

                <label>
                  <span className="inputError">
                    {errors &&
                      errors.email &&
                      errors.email.type === 'required' &&
                      'Email is required'}
                  </span>
                </label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder={t(`email`)}
                  inputClassName="no-group form-padding"
                />

                <Input
                  id="organisation"
                  name="organisation"
                  type="organisation"
                  placeholder={t(`organisation`)}
                  inputClassName="no-group form-padding"
                />
                <div className={styles.actions}>
                  <Button
                    type="submit"
                    className={`button-primary ${styles.registerBtn}`}
                    title={t(`register`)}
                  />
                </div>
              </form>}
            {submitted && <div className={styles.submittedContiner}>
            <h2 className={styles.submittedTitle}>Thank you for registering!</h2>
            <h3 className={styles.submittedEmail}>{registeredData.user.email}</h3>
            <p className={styles.submittedText}> has been registered. Please wait for us to approve your account. 
            When approved you will recieve an email containing your ticket number for the event.</p>
            <p className={styles.submittedText}>We look forward to welcoming you to our event.</p>

            <Button title={t('register another email')} onClick={()=>setSubmitted(false)} />
          </div>}
          </div>
          {!isLoading && <div className={styles.actions}>
            <Card className={`secondary-card ${styles.regBoxWrapper} `}>
            <div className={styles.altContainer}>
            <p className={styles.altContainerText}>{t(`already registered`)}</p>
                <Button
                  type="button"
                  onClick={navLoginHandler}
                  className={`button-warning ${styles.enterBtn}`}
                  title={t(`enter`)}
                />
              </div>
            </Card>
          </div>}
        
        </div>

      </motion.div>
      </AnimatePresence>
    </Fragment>
  );
};

export default Register;
