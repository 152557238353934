import React from 'react';
import './MainFooter.css';

const MainFooter = () => {
return (
    <footer>
    <div className="main-footer__shell">
       
        <div className="main-footer__bottom">
            <p> &copy; Copyright 2021 - Event Streams - Website Developed by <a className='helogale' href='https://helogale.com' target='blank'>Helogale</a></p>
        </div>

    </div>
    </footer>

)};

export default MainFooter;