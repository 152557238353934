import React from 'react';
import {motion} from 'framer-motion'

import styles from './AgendaItem.module.css';

const AgendaItem = ({title, subTitle, startTime, endTime, right}) => {

    return (
        <motion.div 
            layout
            key={title}
            className={`${styles.wrapper} ${right && styles.right}`}
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x:0 }}
            exit={{ opacity: 0, x:150}}
        >
            <h1 className={styles.title}>{title}</h1>
            <h2 className={styles.speaker}>{subTitle}</h2>
            <p className={styles.times}>{`${startTime.slice(0,5)} - ${endTime.slice(0,5)}`}</p>           
        </motion.div>
    )
}

export default AgendaItem;