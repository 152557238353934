import React, {useEffect, useState, useContext} from 'react';
import Vimeo from '@u-wave/react-vimeo';
import cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/UIElements/LoadingSpinner';
import ErrorModal from '../../components/UIElements/Modal/ErrorModal';
import styles from './LiveStream.module.css';

const LiveStream = ({ eventId }) => {

  const history = useHistory();
  const { email, userId, isLoggedIn, token } = useContext(AuthContext);
  const { REACT_APP_API_URL } = process.env;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [currentLang, setCurrentLang] = useState('en');
  const [liveStream, setLiveStream] = useState();


  useEffect(() => {
    const lang = cookies.get('i18next') || 'en';
    setCurrentLang(lang)
    console.log(currentLang);
  }, [])

  useEffect(() => {

    if (isLoggedIn) {
      const fetchEvent = async () => {
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/streams`,
            'POST',
            JSON.stringify({
              eventId: eventId,
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            }
          );
          console.log(response);
          if (response.streams) {
            setLiveStream(response.streams[0])
          }
          
        } catch (err) {
          console.log(err);
        }
       }
       fetchEvent()
     
    } else {
      console.log("Not authorised");
      history.push('/auth');
    }
    

  }, [eventId, currentLang ])

  return (
    <div id={styles.liveStream}>
     <ErrorModal error={error} onClear={clearError} />
      <div id={styles.video}>
        <div className={styles.videoContainer}>
          {isLoading && <LoadingSpinner />}
          {!isLoading && liveStream && <Vimeo className={styles.player} video={liveStream.uri} responsive={true}  width={1066} height={500}/>}
        </div>
      </div>
    </div>
   
  );
};

export default LiveStream;
